<template>
  <div class="dialog-content">
    <div class="content">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('dialogEvent', 'dialogClose')">
        取 消
      </el-button>
      <el-button type="primary" @click="submitForm">
        确 认
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  getSourceOrderData,
  addAutoDistributeGoods
} from "@/api/produce/bill/storeDistribute"; //产品分货单
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "AutoDistributeImport",
  components: { TablePage: () => import("@/components/tablePage/index.vue") },
  mixins: [mixin],
  data() {
    return {
      options: {
        mutiSelect: true,
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: () => {
          return {
          }
        },
        getListApi: getSourceOrderData,
        listNo: true, // 序号
        height: '450px',
        check: [], // 选中数据
        rowKey: "importBillId",
        tableTitle: '',
        hideCard: true,
        noShowColumnSet: true,
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: "单据类型",
            type: "checkbox",
            model: true,
            filter: "isProduce",
            defaultValue: true,
            option: {
              label: '生产计划单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isShopDue",
            defaultValue: false,
            option: {
              label: '门店预订单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isShopMaterialOrder",
            defaultValue: false,
            option: {
              label: '门店原料订货单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isShopOrder",
            defaultValue: false,
            option: {
              label: '门店订货单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isSell",
            defaultValue: false,
            option: {
              label: '销售订单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isProductStorage",
            defaultValue: false,
            option: {
              label: '产品入库单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isProduceOtherOrder",
            defaultValue: false,
            option: {
              label: '其他订货单'
            }
          },
          {
            label: "",
            type: "checkbox",
            model: false,
            filter: "isProduceOtherMaterialOrder",
            defaultValue: false,
            option: {
              label: '其他原料订货单'
            }
          },
          {
            label: "到货日期",
            type: "datetimerange",
            model: "",
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginArrivalDate: a, endArrivalDate: b }),
          },
          this.$select({
            key: "listProduceOrder",
            option: {
              seniorSearch: true,
              filter: "produceOrderIds",
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listCustomer",
            option: {
              seniorSearch: true,
              filter: "customerIds",
              option: { multiple: true },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              seniorSearch: true,
              filter: "inStoreIds",
              option: { multiple: true },
            },
          }),
          this.$select({
            key: 'listProduceDept',
            option: {
              type: 'remote',
              label: '生产工厂',
              filter: 'produceDeptIds',
              seniorSearch: true,
              option: {
                multiple: true
              }
            }
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据日期",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "deptName",
            label: "生产工厂",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceGroupName",
            label: "生产组",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceOrderName",
            label: "生产班次",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billQty",
            label: "数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billMoney",
            label: "金额",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "inStoreName",
            label: "订货仓库",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "arrivalDate",
            label: "到货日期",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "customerNo",
            label: "客户编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "customerName",
            label: "客户名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "billRemark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    handleEvent(...e) {
    },
    async submitForm(e) {
      if (this.options.check.length === 0) {
        return this.$message.error("请选择要引入的单据！");
      }
      let arr = []
      this.options.check.forEach(item => {
        arr.push({
          importType: item.importType,
          importBillId: item.importBillId
        })
      })
      await addAutoDistributeGoods(arr)
      this.$emit('dialogEvent', 'dialogImportChange')
      this.$message.success("引入成功！");
      this.dialog.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>

