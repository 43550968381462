<template>
  <div class="dialog-content">
    <div class="content">
      <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm">
        确 认
      </el-button>
    </div>
  </div>
</template>
<script>
import {
  getCalculateDistributeDetail,
  modifyFactDistributeQty
} from "@/api/produce/bill/autoDistribute"; //智能分货
import { mixin } from "@/components/Dialog/components/mixin.js";
export default {
  name: "FactDistribute",
  components: { TablePage: () => import("@/components/tablePage/index.vue") },
  mixins: [mixin],
  data() {
    return {
      options: {
        hidePagination: true,
        // mutiSelect: true,
        loading: false,
        // pagination: {
        //   total: 0,
        //   page: 1,
        //   size: 15,
        // },
        defaultBody: () => {
          return {
            lineId: this.dialog.lineId,
            orderBy:this.dialog.orderBy
          }
        },
        getDataKey: (res) => {
          return {
            list: res.data.calculateDetails
          }
        },
        getListApi: getCalculateDistributeDetail,
        // listNo: true, // 序号
        height: '450px',
        check: [], // 选中数据
        rowKey: "lineId",
        //表格
        columns: [
          {
            prop: "clientNo",
            label: "分店/客户编号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "clientName",
            label: "分店/客户名称",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "clientTypeName",
            label: "客户类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "belongShopName",
            label: "所属分店",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "orderQty",
            label: "订货总数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "distributeQty",
            label: "预配数",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "diffQty",
            label: "差异数量",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "factDistributeQty",
            label: "实配数",
            minWidth: 120,
            align: "center",
            type: 'my-input'
          },
          {
            prop: "orderSortNo",
            label: "订货排名",
            minWidth: 120,
            align: "center",
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    handleEvent(...e) {
    },
    async submitForm(e) {
      let arr = []
      this.options.list.forEach(item => {
        arr.push({ lineDetailId: item.lineDetailId, factDistributeQty: item.factDistributeQty })
      })
      if (arr.length == 0) return this.$message.error("没有可修改的实配数!")
      await modifyFactDistributeQty(arr)
      this.$message.success("保存成功！");
      this.dialog.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-content {
  ::v-deep #table-page {
    height: auto;
    padding: 0;
  }
  ::v-deep .el-card {
    margin-bottom: 0px !important;
    border: none !important;
    box-shadow: none !important;
    .cardContent {
      padding: 0 !important;
    }
  }
}
</style>

